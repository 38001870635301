import { formatPrice } from '@corratech/tag-manager';

function AddToCartEvents(tagData) {
    const {
        id,
        name,
        price,
        quantityChange,
        category,
        variant,
        brand,
        urlKey,
        thumbnailUrl,
        productsDetails = [],
        currencyCode,
        cart,
        total,
        taxAmount,
        product
    } = tagData;

    const products = productsDetails.reverse();
    if (products.length > 0) {
        products.forEach((product) => {
            if (cart && Array.isArray(cart.items)) {
                cart.items.forEach((item) => {
                    if (item.itemsku === product.id) {
                        let itemDiscount = 0;
                        if (item.prices?.discounts) {
                            item.prices.discounts.forEach((discount) => {
                                itemDiscount += discount.amount.value;
                            });
                        }
                        product.discountValue = itemDiscount / product.quantity;
                        product.originalPrice = product.price;
                        product.variantId = item.itemsku;
                        product.productId = item.product?.sku ?? item.itemsku;
                    }
                });
            }
        });
    }

    const selectedProduct = products.find(product => product.id === id);

    return {
        event: 'addToCart',
        ecommerce: {
            currencyCode,
            add: {
                products: [
                    {
                        id,
                        name,
                        price: formatPrice(price),
                        quantity: quantityChange,
                        category,
                        variant,
                        brand,
                        urlKey,
                        thumbnailUrl,
                        discountValue: selectedProduct?.discountValue || 0,
                        originalPrice: selectedProduct?.originalPrice || price,
                        variantId: selectedProduct?.variantId || id || '',
                        productId: selectedProduct?.productId || product?.sku || ''
                    }
                ]
            }
        },
        cartContents: {
            currencyCode,
            products: products,
            totalPrice: total,
            totalPriceWithoutTax: (total - taxAmount).toFixed(2)
        },
        _clear: true
    };
}
export { AddToCartEvents };
