import React, { useState, useContext, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { CartStore, LoaderStore } from '@corratech/context-provider';
import OrderConfirmation from '@corratech/checkout/OrderConfirmation/OrderConfirmation';
import setPaymentMethodOnCart from '@corratech/checkout/PaymentMethods/PaypalExpress/setPaypalPaymentMethod.graphql';
import placeOrderMutation from '@corratech/checkout/graphql/placeOrder.graphql';
import { useTranslation } from 'react-i18next';
import { PaymentFailure } from 'ModulesPath/Checkout';
import { EmptyCartCheckoutBlock } from '@corratech/checkout';

export const PayPalPlaceOrderSuccess = props => {
    const { dataLayerAction } = props;
    const { cartState, dispatch } = useContext(CartStore);
    const LoadingIndicator = useContext(LoaderStore);
    const [showLoader, setShowLoader] = useState(true);
    const [placingOrder, setPlacingOrder] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showEmptyCart, setShowEmptyCart] = useState(false);
    const [token, setToken] = useState(null);
    const [payerId, setPayerId] = useState(null);

    const [enteredPassword, setEnteredPassword] = useState('');
    const [joinRewards, setJoinRewards] = useState(false);
    const [error, setError] = useState(false);
    const [t] = useTranslation();

    const [setPayPalPaymentMethod, { loading }] = useMutation(
        setPaymentMethodOnCart,
        {
            variables: {
                cartId: cartState.cartId,
                paypalToken: token,
                paypalPayerId: payerId
            },
            onCompleted: res => {
                dispatch({
                    type: 'SET_CART',
                    cart: res.setPaymentMethodOnCart.cart
                });
                if (res) {
                    placeOrder();
                }
            },
            onError: error => {
                setShowLoader(false);
                setShowError(true);
                let errorFlag = false;
                let errorMsg = null;

                if (error?.graphQLErrors) {
                    for (var idx = 0; idx < error.graphQLErrors.length; idx++) {
                        if (
                            error.graphQLErrors[idx]?.extensions?.category ===
                            'graphql-input'
                        ) {
                            errorMsg = error.graphQLErrors[idx]?.message;
                            errorFlag = true;
                        }
                    }
                }

                if (errorMsg && errorMsg) {
                    setError(errorMsg);
                }
            }
        }
    );

    const setPaymentMethod = () => {
        setPlacingOrder(true);
        setPayPalPaymentMethod({
            variables: {
                cartId: cartState.cartId
            }
        });
    };

    const checkIsFromPayPal = () => {
        const paypalToken = window.location.search.replace('?token=', '');

        return paypalToken ? setPaymentMethod() : false;
    };

    // Mutation to placeorder
    const [placeOrder] = useMutation(placeOrderMutation, {
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: res => {
            dataLayerAction({
                type: 'PLACE_ORDER',
                data: {
                    ...cartState,
                    actionField: res
                }
            });
            dataLayerAction({
                type: 'TRIGGER_IMMEDIATELY',
                data: {
                    eventName: 'purchase'
                }
            });
            dispatch({
                type: 'PLACED_ORDER',
                placedOrder: res
            });
            setShowLoader(false);
        },
        onError: error => {
            setShowLoader(false);
            setShowError(true);
        }
    });
    useEffect(() => {
        if (payerId && token) {
            if (cartState && cartState.cartId && !placingOrder) {
                checkIsFromPayPal();
            } else {
                if (!cartState?.cartId && !showError && !cartState?.placedOrder) {
                    setShowLoader(false);
                    setShowEmptyCart(true);
                }
            }
        }
    }, [payerId, token, cartState]);

    useEffect(() => {
        if (showError) {
            dispatch({
                type: 'CLEAR_CART'
            });
        }
    }, [showError]);

    useEffect(() => {
        const dataFromURL = window.location.search.split('&');
        setToken(dataFromURL[0].replace('?token=', ''));
        setPayerId(dataFromURL[1].replace('PayerID=', ''));
    }, []);
    if (showError) {
        return (
            <div>
                <h3 style={{ textAlign: 'center' }}>Place order failed</h3>
                {error && (
                    <div style={{ textAlign: 'center' }}>
                        <PaymentFailure />
                    </div>
                )}
            </div>
        );
    }
    if (showEmptyCart) {
        return (
            <EmptyCartCheckoutBlock />
        );
    }
    if (showLoader) {
        return <LoadingIndicator />;
    }
    return (
        <>
            {cartState.placedOrder && (
                <OrderConfirmation
                    setEnteredPassword={setEnteredPassword}
                    enteredPassword={enteredPassword}
                    joinRewards={joinRewards}
                    setJoinRewards={setJoinRewards}
                />
            )}
        </>
    );
};
