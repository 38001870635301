import React, {
    useContext,
    Fragment,
    useState,
    useEffect,
    useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useLazyQuery } from 'react-apollo';
import { CartStore } from '@corratech/context-provider';
import { CollapsibleContainer } from '@corratech/collapsible-container';
import { Button } from 'react-bootstrap';
import applyGiftCardToCart from './Queries/applyGiftCardToCart.graphql';
import giftCardAccountFromCart from './Queries/giftCardAccount.graphql';
import { Check as CheckIcon, AlertCircle as ErrorIcon } from 'react-feather';
import { getCurrencySymbol } from './util/getCurrencySymbol';
import { setPriceZeroes } from './util/setPriceZeroes';
import { Form } from 'informed';

export const GiftCard = props => {
    const [t] = useTranslation();
    const { cartState, dispatch } = useContext(CartStore);
    const [isToggle, setIsToggle] = useState(false);
    const [giftCode, setGiftCode] = useState('');
    const [giftBalanceCheck, setGiftBalanceCheck] = useState(null);
    const [giftCodeError, setGiftCodeError] = useState(false);
    const [giftCardError, setGiftCardError] = useState(false);
    const [giftAppliedSuccess, setGiftAppliedSuccess] = useState(false);
    const [giftCardSubscribedError, setGiftCardSubscribedError] = useState(
        false
    );

    const [applyGiftCard, { loading }] = useMutation(applyGiftCardToCart, {
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: ({ validateApplyGiftCardToCart }) => {
            if (validateApplyGiftCardToCart.error_message) {
                setIsToggle(true);
                setGiftCode('');
                setGiftCardError(validateApplyGiftCardToCart.error_message);
            } else {
                dispatch({
                    type: 'SET_CART',
                    cart: validateApplyGiftCardToCart.cart
                });
                setIsToggle(true);
                setGiftCodeError(false);
                setGiftAppliedSuccess(true);
                setGiftCode('');
                setGiftCardError(false);
            }
        },
        onError: res => {
            if (res?.graphQLErrors) {
                for (var idx = 0; idx < res.graphQLErrors.length; idx++) {
                    if (
                        res.graphQLErrors[idx]?.extensions?.category ===
                        'graphql-giftcard-subscribed'
                    ) {
                        setGiftCardSubscribedError(
                            res.graphQLErrors[idx]?.message
                        );
                    }
                }
            }
            setGiftCodeError(true);
            setGiftCardError(false);
        }
    });

    const [checkGiftCardAccount, { loading: loadingBalance }] = useLazyQuery(
        giftCardAccountFromCart,
        {
            fetchPolicy: 'cache-and-network',
            onCompleted: res => {
                setGiftBalanceCheck(res.giftCardAccount);
                setGiftCodeError(false);
                setGiftAppliedSuccess(false);
            },
            onError: res => {
                setGiftCodeError(true);
            }
        }
    );

    useEffect(() => {
        setGiftBalanceCheck(null);
        setGiftCodeError(false);
        setGiftAppliedSuccess(false);
        return () => {
            setGiftCode('');
        };
    }, [cartState.cart.applied_gift_cards]);

    const applyGiftCardEvent = useCallback(() => {
        if (giftCode.trim()) {
            applyGiftCard({
                variables: {
                    giftCode: giftCode,
                    isSignedIn: !!cartState.cart.authenticated
                }
            });
        } else {
            setGiftCodeError(true);
        }
    }, [giftCode]);

    const handleChange = event => {
        const value = event.target.value.replace(/\s+/g, '');
        setGiftCode(value);
        setGiftCodeError(false);
        setGiftBalanceCheck(null);
    };

    const GiftCardBlock = () => (
        <Fragment>
            <div className={'promo-text-filed'}>
                <>
                    <Form onSubmit={!loading && applyGiftCardEvent}>
                        <div className={'form-field'}>
                            <label className="label" htmlFor={'applyGift'}>
                                {t('Apply A Gift Card')}
                            </label>
                            <input
                                data-cs-mask
                                type="text"
                                field="gift-card-code"
                                value={giftCode}
                                autoComplete="off"
                                onChange={handleChange}
                                id={'applyGift'}
                            />{' '}
                        </div>
                        <Button
                            onClick={applyGiftCardEvent}
                            disabled={loading || giftCode === ''}
                            size="lg"
                            variant="primary"
                        >
                            {loading ? t('Applying') : t('Apply')}
                        </Button>
                    </Form>
                    {giftAppliedSuccess && !giftCodeError && (
                        <div className={'applied-coupon'}>
                            <div className={'applied-gift-success'}>
                                <CheckIcon size={24} color={'#89ac3c'} />
                                {t('Gift Card successfully applied!')}
                            </div>
                        </div>
                    )}
                    {!giftCardSubscribedError && giftCodeError && (
                        <div className={'coupon-error'}>
                            <span className={'message'}>
                                <ErrorIcon size={24} color={'#B70020'} />
                                {t(
                                    `Sorry, that gift card number is not valid.`
                                )}
                            </span>
                        </div>
                    )}
                    {giftCardSubscribedError && (
                        <div className={'coupon-error'}>
                            <span className={'message'}>
                                <ErrorIcon size={24} color={'#B70020'} />
                                {t(giftCardSubscribedError)}
                            </span>
                        </div>
                    )}
                    {giftCardError && (
                        <div className={'coupon-error'}>
                            <span className={'message'}>
                                <ErrorIcon size={24} color={'#B70020'} />
                                {t(giftCardError)}
                            </span>
                        </div>
                    )}
                </>
                <div className="see-balance-gift">
                    {giftBalanceCheck && !giftCodeError && (
                        <div className={'bal-gift-attributes'}>
                            <span>
                                {t('Code')}: {giftBalanceCheck.code}
                            </span>
                            <span>
                                {t('Balance')}:{' '}
                                {getCurrencySymbol(
                                    giftBalanceCheck.balance.currency
                                ) +
                                    setPriceZeroes(
                                        giftBalanceCheck.balance.value
                                    )}
                            </span>
                            {giftBalanceCheck.expiration_date && (
                                <span>
                                    {t('Expiry')}:{' '}
                                    {giftBalanceCheck.expiration_date}
                                </span>
                            )}
                        </div>
                    )}
                    <Button
                        variant={'link'}
                        style={{ position: 'static' }}
                        onClick={() =>
                            checkGiftCardAccount({
                                variables: {
                                    giftCode: giftCode
                                }
                            })
                        }
                        disabled={loadingBalance}
                    >
                        {t('See Balance')}
                    </Button>
                </div>
            </div>
        </Fragment>
    );

    return (
        <div
            className={'coupon-form ' + (props.className || '')}
            css={props.css}
        >
            <div className={'discount-coupon-form accordion-wrapper'}>
                <CollapsibleContainer
                    title={t('Apply A Gift Card')}
                    children={GiftCardBlock()}
                    toggleAccordion={isToggle}
                />
            </div>
        </div>
    );
};
