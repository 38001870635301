import {
    GTMCheckoutOptionEvents,
    GTMOrderConfirmationEvents,
    GTMSearchEvents,
    GTMNewsLetterSubscriptionEvents,
    GTMFormSubmitEvents,
    GTMCheckoutEvents,
    GTMEnhancedConversionEvents,
    BTPageViewEvents,
    CustomerLoginEvent
} from './GTMEvents';

export const GTMActionOverride = {
    SEARCH: tagData => {
        return GTMSearchEvents(tagData);
    },
    CHECKOUT_OPTION: tagData => {
        return GTMCheckoutOptionEvents(tagData);
    },
    PLACE_ORDER: tagData => {
        return GTMOrderConfirmationEvents(tagData);
    },
    /*NEWSLETTER_SUBSCRIBE: tagData => {
        return GTMNewsLetterSubscriptionEvents(tagData);
    },*/
    FORM_SUBMIT: tagData => {
        return GTMFormSubmitEvents(tagData);
    },
    CUSTOMER_LOGIN: tagData => {
        return CustomerLoginEvent(tagData, 'login');
    },
    CUSTOMER_REGISTRATION: tagData => {
        return CustomerLoginEvent(tagData, 'register');
    },
    RESET_ECOMMERCE: () => {
        return {
            ecommerce: undefined
        };
    },
    CHECKOUT_PAGE: data => {
        return GTMCheckoutEvents(data);
    },
    ENHANCED_CONVERSION: data => {
        return GTMEnhancedConversionEvents(data);
    },
    BT_PAGE_VIEW_START: data => {
        const event = 'page_view_start';
        return BTPageViewEvents(event, data);
    },
    BT_PAGE_VIEW_COMPLETE: data => {
        const event = 'page_view_complete';
        return BTPageViewEvents(event, data);
    }
};
