import {
    AuthStore,
    signIn,
    signOut,
    useGlobalOptions
} from '@corratech/context-provider';
import {
    combine,
    Field,
    isRequired,
    Message,
    TextInput,
    validateEmail
} from '@corratech/form-components';
import { Form } from 'informed';
import { bool, func, object, string } from 'prop-types';
import React, {useCallback, useContext, useRef, useState} from 'react';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './LoginForm.less';
import { useReCaptchaStoreConfig } from 'ModulesPath/Checkout/useReCaptchaStoreConfig';
import { ReCaptcha } from 'react-recaptcha-v3';
import loadReCaptcha from '@corratech/google-recaptcha-v3/src/loadReCaptcha';
import { handleEmailChange } from 'UtilPath/handleEmailChange';
import { useDebouncedCallback } from 'UtilPath/useDebouncedCallback';

export const ReLoginForm = ({
    className,
    css,
    requiredText,
    invalidEmailText,
    setShowModal,
    email,
    setEmail,
    setShowForgotPassword,
    setTimeoutStored,
    timeoutStored
}) => {
    const [t] = useTranslation();
    const { authState, dispatch } = useContext(AuthStore);
    const formApiRef = useRef(null);
    const setFormApi = useCallback(api => (formApiRef.current = api), []);

    //ReCaptcha
    const options = useGlobalOptions();
    const { actionV3PlaceSingIn } = useReCaptchaStoreConfig();
    const [reCaptchaTokenSingIn, setReCaptchaTokenSingIn] = useState(null);

    let recaptchaV3Publickey = '';
    let isV3PlaceSingInCaptchaEnable = false;

    if (options.storeConfig && options.storeConfig.recaptcha_v3_public_key) {
        recaptchaV3Publickey = options.storeConfig.recaptcha_v3_public_key;
    }

    if (actionV3PlaceSingIn && actionV3PlaceSingIn === 'recaptcha_v3') {
        isV3PlaceSingInCaptchaEnable = true;
    }

    const verifyReCaptchaTokenSingInCallback = token => {
        setReCaptchaTokenSingIn(token);
        hideErrorMessage();
        setInterval(function() {
            resetReCaptchaTokenSingIn();
        }, 110 * 1000);
    };

    const resetReCaptchaTokenSingIn = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, { action: 'sing_in' })
                .then(function(token) {
                    setReCaptchaTokenSingIn(token);
                });
        }
    };

    const onEmailChange = useDebouncedCallback(
        (value) => handleEmailChange(value, formApiRef, setEmail, hideErrorMessage),
        300
    );

    const hideErrorMessage = () => {
        dispatch({
            type: 'SET_AUTH_ERROR',
            error: null
        });
    };

    const onSubmitHandle = formState => {
        signIn({
            credentials: {
                username: formState.email,
                password: formState.password,
                reCaptchaToken: reCaptchaTokenSingIn
            },
            dispatch: dispatch,
            setShowModal: setShowModal
        });
        resetReCaptchaTokenSingIn();
    };

    return (
        <div
            aria-labelledby={'account-login-form-heading'}
            className={
                'account-login-form account-form-wrapper ' + (className || '')
            }
            css={css}
        >
            {isV3PlaceSingInCaptchaEnable &&
                loadReCaptcha(recaptchaV3Publickey)}
            <Form onSubmit={onSubmitHandle} getApi={setFormApi}>
                <Field
                    label={t('Email')}
                    labelText={t('email_address')}
                    required={true}
                >
                    {isV3PlaceSingInCaptchaEnable && (
                        <ReCaptcha
                            action="sing_in"
                            sitekey={recaptchaV3Publickey}
                            verifyCallback={verifyReCaptchaTokenSingInCallback}
                        />
                    )}
                    <TextInput
                        type="email"
                        field="email"
                        autoComplete="email"
                        placeholder={t(`Please enter your email address`)}
                        id="email_address"
                        aria-required="true"
                        validate={combine([
                            {
                                fn: isRequired,
                                text: t(requiredText)
                            },
                            {
                                fn: validateEmail,
                                text: t(invalidEmailText)
                            }
                        ])}
                        initialValue={email}
                        validateOnBlur
                        onValueChange={onEmailChange}
                        data-cs-mask
                    />
                </Field>

                <Field
                    label={t(`Password`)}
                    labelText={t(`password`)}
                    required={true}
                >
                    <TextInput
                        field="password"
                        type="password"
                        autoComplete="new-password"
                        placeholder={t(`Password`)}
                        id="password"
                        aria-required="true"
                        validate={combine([
                            {
                                fn: isRequired,
                                text: t(requiredText)
                            }
                        ])}
                        validateOnChange
                        validateOnBlur
                        autoFocus
                        onValueChange={hideErrorMessage}
                    />
                </Field>

                <div>
                    {isV3PlaceSingInCaptchaEnable && null !== authState.error && (
                        <Alert variant="danger">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: authState.error
                                }}
                            />
                        </Alert>
                    )}
                </div>
                <div className={'actions-toolbar'}>
                    <Button type="submit" size="lg" variant="primary" block>
                        {t('Sign In')}
                    </Button>

                    <Link
                        title={t('Forgot password?')}
                        to={'#'}
                        onClick={() => setShowForgotPassword(true)}
                        className={'forgot-password-link'}
                    >
                        {t('Forgot password?')}
                    </Link>
                </div>
            </Form>
            <Button
                onClick={() => {
                    if (timeoutStored) {
                        clearTimeout(timeoutStored);
                        setTimeoutStored(null);
                    }
                    setShowModal(false);
                }}
                size="lg"
                variant="link"
                block
            >
                {t('Close and Continue as Guest Customer')}
            </Button>
        </div>
    );
};

ReLoginForm.propTypes = {
    className: string,
    css: object,
    email: string,
    updateEmail: func,
    showLogin: bool,
    requiredText: string,
    invalidEmailText: string
};

ReLoginForm.defaultProps = {
    requiredText: 'This field is required',
    invalidEmailText: 'Please enter a valid email, such as example@example.com'
};
