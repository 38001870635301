import { RestApi, Util } from '@magento/peregrine';
import { validateEmail } from '@corratech/form-components';

const { request } = RestApi.Magento2;
const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();

// Store url
const store_url_string =
    typeof window.STORE_CODE !== 'undefined' && window.STORE_CODE !== ''
        ? window.STORE_CODE + '/'
        : '';

const gqlFetch = async ({ query, options }) => {
    const { headers, ...restOpts } = options;

    const mergedOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...headers },
        body: JSON.stringify({ query }),
        ...restOpts
    };

    const fetchResult = await fetch('/graphql', mergedOpts);
    return await fetchResult.json();
};

/**
 * Showing error message when create user fails
 * @param {*} error
 */
const getCreateUserErrorMessage = error => {
    try {
        const { baseMessage } = Object.assign({}, error),
            additionInfo = error.message.split('Additional info:');
        let additionInfoJson,
            errorMessage = '';
        if (baseMessage.includes('%1')) {
            if (additionInfo && additionInfo.length >= 2) {
                additionInfoJson = JSON.parse(additionInfo[1]);
                if (
                    additionInfoJson.parameters &&
                    additionInfoJson.parameters[0]
                ) {
                    if (
                        baseMessage.includes(
                            'Minimum of different classes of characters in password is'
                        )
                    ) {
                        return `Passwords must be 8 characters long and include each of the following: lowercase letter, uppercase letter, number, and special character. Please re-enter your password and try again.`;
                    } else if (
                        baseMessage.includes('The password needs at least')
                    ) {
                        return `Passwords must be ${additionInfoJson.parameters[0]} characters long and include each of the following: lowercase letter, uppercase letter, number, and special character. Please re-enter your password and try again.`;
                    } else {
                        return baseMessage.replace(
                            '%1',
                            additionInfoJson.parameters[0]
                        );
                    }
                }
            }
        } else if (baseMessage) {
            return baseMessage;
        }
    } catch (e) {
        console.log(e);
    }
    return 'Sorry, something went wrong. Please try again';
};

export const isSignedIn = () => !!storage.getItem('signin_token');

export const createUser = async (body, callback) => {
    // API is expecting a payload with `password` string, `confirm` string, and `customer` object
    // so we move the other properties (...) to the customer object
    const reCaptchaTokenRegister = body.reCaptchaTokenRegister;
    const reCaptchaTokenSingIn = body.reCaptchaTokenSingIn;
    const { password, confirm, ...customer } = body.accountInfo;
    let errorMessage = '';

    if (!validateEmail(customer.email)) {
        try {
            const signin_token = storage.getItem('signin_token');
            let customerData = {};

            let headers = new Headers({
                'Content-type': 'application/json',
                Accept: 'application/json',
                Authorization: signin_token ? `Bearer ${signin_token}` : '',
                'X-ReCaptcha': reCaptchaTokenRegister
            });

            const registrationResponse = await fetch('/rest/V1/customers', {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({ password, confirm, customer })
            });

            if (!registrationResponse.ok) {
                const errorData = await registrationResponse.json();
                throw new Error(errorData.message);
            }

            if (registrationResponse.ok) {
                await signIn({
                    credentials: {
                        username: customer.email,
                        password: password,
                        reCaptchaToken: reCaptchaTokenSingIn
                    },
                    dispatch: body.dispatch
                });

                customerData = await getUserDetails(body.dispatch);
            }

            if (callback) {
                callback(true);
            }

            return customerData;
        } catch (error) {
            errorMessage = getCreateUserErrorMessage(error);

            if (error.message.includes('reCAPTCHA')) {
                errorMessage = error.message;
            }

            if (callback) {
                callback(false, errorMessage);
            }
            console.log(error.message);
        }
    } else {
        console.log(
            `Account with email ${props.accountInfo.email} already exists!`
        );
    }
};

export const signIn = async props => {
    const { credentials, dispatch, setShowModal } = props;

    try {
        const tokenRes = await gqlFetch({
            query: `
            mutation {
                generateCustomerToken(
                    email: "${credentials.username}"
                    password: "${credentials.password}"
                ) {
                    token
                }
            }`,
            options: {
                headers: {
                    Store: window.STORE_CODE || '',
                    'X-ReCaptcha': credentials.reCaptchaToken || ''
                }
            }
        });

        if (setShowModal) {
            setShowModal(false);
        }

        //Check for unsuccessful login condition
        if (tokenRes.errors && tokenRes.errors[0].message !== null) {
            dispatch({
                type: 'SET_AUTH_ERROR',
                error: tokenRes.errors[0].message
            });
        } else {
            const newToken = tokenRes.data.generateCustomerToken.token;

            setToken(newToken);

            dispatch({
                type: 'SET_AUTH_TOKEN',
                token: newToken,
                justSignedIn: true
            });

            const userDetails = await getUserDetails(dispatch);
            return userDetails;
        }
    } catch (error) {
        const { baseMessage } = Object.assign({}, error);
        dispatch({
            type: 'SET_AUTH_ERROR',
            error: baseMessage
        });
    }
};
export const signInWithLoyalty = async props => {
    const { credentials, dispatch, setShowModal } = props;

    try {
        const body = {
            username: credentials.username,
            password: credentials.password,
            listrakNewsletterOptIn: credentials.listrakNewsletterOptIn,
            reCaptchaToken: credentials.reCaptchaToken
        };

        const response = await request(
            '/rest/V1/integration/customer/createToken',
            {
                method: 'POST',
                body: JSON.stringify(body)
            }
        );

        if (setShowModal) {
            setShowModal(false);
        }

        setToken(response.token);

        dispatch({
            type: 'SET_AUTH_TOKEN',
            token: response.token,
            justSignedIn: true
        });

        const userDetails = await getUserDetails(dispatch);

        dataLayerAction({
            type: 'CUSTOMER_LOGIN',
            data: userDetails
        });
    } catch (error) {
        const { baseMessage } = Object.assign({}, error);

        dispatch({
            type: 'SET_AUTH_ERROR',
            error: baseMessage
        });
    }
};

export const getUserDetails = async dispatch => {
    try {
        if (isSignedIn()) {
            const userDetails = await request('/rest/V1/customers/me', {
                method: 'GET'
            });

            dispatch({
                type: 'SET_USER_DATA',
                user: userDetails
            });

            return userDetails;
        } else {
            throw 'User not logged in';
        }
    } catch (error) {
        console.log(error);
        throw error;
    }
};

// can replace with: export const signOut = async ({ history, dispatch }) => {
export const signOut = async ({ dispatch }) => {
    // Sign the user out in local storage and Redux.
    await clearToken();
    dispatch({
        type: 'SIGN_OUT',
        justLogout: true
    });

    // Now that we're signed out, forget the old (customer) cart
    // and fetch a new guest cart.
    //await removeCart();
    //getCartDetails({ forceRefresh: true }));

    // Finally, go back to the first page of the browser history.
    //refresh({ history });
};

export const resetPassword = async (body, callback) => {
    if (!isSignedIn()) {
        try {
            await request(
                '/rest/' + store_url_string + 'V1/customers/password',
                {
                    method: 'PUT',
                    body: JSON.stringify(body)
                }
            );
            if (callback) {
                callback({ success: true });
            }
        } catch (error) {
            let errorMessage = error.message;
            if (errorMessage.includes('Message:')) {
                errorMessage = errorMessage.split('Message:')[1].trim();
            } else {
                errorMessage = '';
            }

            if (callback) {
                callback({ success: false, errorMessage: errorMessage });
            }
            console.log(error.message);
        }
    }
};

export const changePassword = async (body, callback) => {
    try {
        if (!isSignedIn()) {
            const changePasswordRes = await gqlFetch({
                query: `
                mutation {
                    resetPassword(
                    email: "${body.email}",
                    resetPasswordToken: "${body.resetToken}",
                    newPassword: "${body.newPassword}"
                    )
                }`,
                options: {
                    headers: {
                        Store: window.STORE_CODE || ''
                    }
                }
            });

            if (changePasswordRes.data.resetPassword) {
                if (callback) {
                    callback(true);
                }
            } else {
                let baseMessage =
                    changePasswordRes.errors.length > 0
                        ? changePasswordRes.errors[0].message
                        : '';

                if (callback) {
                    callback(false, baseMessage);
                }
            }
        }
    } catch (error) {
        console.log(error);
    }
};

async function setToken(token) {
    // TODO: Get correct token expire time from API

    // This stores an auth token with a time of creation and ttl (time until expiration in seconds)
    // On page load, this will be deleted if (time > creation_time + ttl)
    return storage.setItem('signin_token', token);
}

async function clearToken() {
    return storage.removeItem('signin_token');
}
