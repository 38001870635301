import React, { useContext, useState, useEffect } from 'react';
import { CartStore, LoaderStore } from '@corratech/context-provider';
import { useMutation } from 'react-apollo';
import setFreePaymentMethod from './setPaymentMethod.graphql';
import placeOrderMutation from '../../graphql/placeOrder.graphql';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { CheckoutAgreements } from '../../CheckoutAgreements/CheckoutAgreements';
import setBillingAddressMutation from '../../graphql/setBillingAddressOnCart.graphql';
import { AuthBillingAddressForm } from '../BraintreePayment/AuthBillingAddressForm';
import { UnAuthBillingAddressForm } from '../BraintreePayment/UnAuthBillingAddressForm';
import { isAddressValid } from '../../util/isAddressValid';
import { RenderAppliedCredits } from '../../RenderAppliedCredits';
import { PrivacyPolicy } from 'ModulesPath/Checkout';
import NewsletterCheckbox from '../../NewsletterCheckbox/NewsletterCheckbox';
import { orderGrooveOptins, getCartIds, extractOgOptions } from 'UtilPath/orderGroove';

export const FreeCheckout = props => {
    const { dataLayerAction } = props;
    const { cartState, dispatch } = useContext(CartStore);
    const LoadingIndicator = useContext(LoaderStore);
    const [isEmailError, setEmailError] = useState(false);
    const [isBillingError, setBillingError] = useState(false);
    const [t] = useTranslation();
    const [setFreePaymentMethodFunc, { data, loading, error }] = useMutation(
        setFreePaymentMethod,
        {
            variables: {
                cartId: cartState.cartId,
                method: 'free'
            }
        }
    );
    const cartOGItemsId = getCartIds(cartState);
    const OGProducts = orderGrooveOptins(cartOGItemsId);
    const OGProductIds = OGProducts?.map(ogProduct => ogProduct.product);

    // Mutation to placeorder
    const [placeOrder, { loading: placeOrderLoading }] = useMutation(
        placeOrderMutation,
        {
            variables: {
                cartId: cartState.cartId,
                OGProductIds: OGProductIds.toString(),
                ogOptions: extractOgOptions(OGProducts)
            },
            onCompleted: res => {
                if (!!dataLayerAction) {
                    dataLayerAction({
                        type: 'PLACE_ORDER',
                        data: {
                            ...cartState,
                            actionField: res
                        }
                    });
                }
                dispatch({
                    type: 'PLACED_ORDER',
                    placedOrder: res
                });
            }
        }
    );

    // Magento requires a billing address be set on all orders, so we set it to the billing address if same as shipping
    const [setBillingAddress, { loading: addressLoading }] = useMutation(
        setBillingAddressMutation
    );

    useEffect(() => {
        if (cartState.cart.email) setEmailError(false);
        if (cartState.cart.billing_address) setBillingError(false);
    }, [cartState.cart]);

    if (!addressLoading)
        return (
            <div className={'free-payment-method'}>
                <div className="free-checkout-message">
                    {t('No additional payment required.')}
                </div>

                <br />

                {cartState.cart.is_virtual && (
                    <>
                        {cartState.cart.authenticated ? (
                            <AuthBillingAddressForm />
                        ) : (
                            <UnAuthBillingAddressForm />
                        )}
                    </>
                )}

                <RenderAppliedCredits
                    className={'checkout-applied-credits free-method'}
                />

                {isEmailError && (
                    <div className={'payment-error message-error'}>
                        {t(
                            'Guest email for cart is missing. Please enter a valid email address.'
                        )}
                    </div>
                )}
                {isBillingError && (
                    <div className={'payment-error message-error'}>
                        {t(
                            'Please select a billing address to place your order.'
                        )}
                    </div>
                )}
                <NewsletterCheckbox />
                <PrivacyPolicy privacyPolicyLink={'/privacy-policy'} />
                {cartState.cart.isBillingSameAsShipping ? (
                    <Button
                        disabled={loading || placeOrderLoading}
                        onClick={() => {
                            setFreePaymentMethodFunc().then(() =>
                                setBillingAddress({
                                    variables: {
                                        cartId: cartState.cartId,
                                        firstname:
                                            cartState.cart.shipping_addresses[0]
                                                .firstname,
                                        lastname:
                                            cartState.cart.shipping_addresses[0]
                                                .lastname,
                                        company:
                                            cartState.cart.shipping_addresses[0]
                                                .company || '',
                                        street:
                                            cartState.cart.shipping_addresses[0]
                                                .street,
                                        city:
                                            cartState.cart.shipping_addresses[0]
                                                .city,
                                        region:
                                            cartState.cart.shipping_addresses[0]
                                                .region.code,
                                        postcode:
                                            cartState.cart.shipping_addresses[0]
                                                .postcode,
                                        countryCode:
                                            cartState.cart.shipping_addresses[0]
                                                .country.code,
                                        telephone:
                                            cartState.cart.shipping_addresses[0]
                                                .telephone
                                    }
                                }).then(() => placeOrder())
                            );
                        }}
                    >
                        {loading || placeOrderLoading
                            ? t('Placing Order...')
                            : t('Place My Order')}
                    </Button>
                ) : (
                    <Button
                        disabled={
                            !isAddressValid(cartState.cart.billing_address) ||
                            loading ||
                            placeOrderLoading
                        }
                        onClick={() => {
                            if (cartState.cart.is_virtual) {
                                if (!cartState.cart.billing_address) {
                                    setBillingError(true);
                                    return false;
                                } else if (!cartState.cart.email) {
                                    setEmailError(true);
                                    return false;
                                } else {
                                    setFreePaymentMethodFunc({
                                        variables: {
                                            cartId: cartState.cartId,
                                            firstname:
                                                cartState.cart.billing_address
                                                    .firstname,
                                            lastname:
                                                cartState.cart.billing_address
                                                    .lastname,
                                            company:
                                                cartState.cart.billing_address
                                                    .company || '',
                                            street:
                                                cartState.cart.billing_address
                                                    .street,
                                            city:
                                                cartState.cart.billing_address
                                                    .city,
                                            region:
                                                cartState.cart.billing_address
                                                    .region.code,
                                            postcode:
                                                cartState.cart.billing_address
                                                    .postcode,
                                            countryCode:
                                                cartState.cart.billing_address
                                                    .country.code,
                                            telephone:
                                                cartState.cart.billing_address
                                                    .telephone
                                        }
                                    }).then(() => placeOrder());
                                }
                            } else {
                                setFreePaymentMethodFunc({
                                    variables: {
                                        cartId: cartState.cartId,
                                        firstname:
                                            cartState.cart.billing_address
                                                .firstname,
                                        lastname:
                                            cartState.cart.billing_address
                                                .lastname,
                                        company:
                                            cartState.cart.billing_address
                                                .company || '',
                                        street:
                                            cartState.cart.billing_address
                                                .street,
                                        city:
                                            cartState.cart.billing_address.city,
                                        region:
                                            cartState.cart.billing_address
                                                .region.code,
                                        postcode:
                                            cartState.cart.billing_address
                                                .postcode,
                                        countryCode:
                                            cartState.cart.billing_address
                                                .country.code,
                                        telephone:
                                            cartState.cart.billing_address
                                                .telephone
                                    }
                                }).then(() => placeOrder());
                            }
                        }}
                    >
                        {loading || placeOrderLoading
                            ? t('Placing Order...')
                            : t('Place My Order')}
                    </Button>
                )}
                <CheckoutAgreements />
            </div>
        );
    else return <LoadingIndicator />;
};
