import jsSha256 from "jssha/sha256";

function CustomerLoginEvent(data, eventName) {
    let telephone = null;

    data.custom_attributes.forEach(attribute => {
        if (attribute.attribute_code === "telephone") {
            telephone = attribute.value;
        }
    });

    const encrypt = msg => {
        const shaObj = new jsSha256("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj.update(msg);
        return {content : shaObj.getHash('HEX')};
    };

    return {
        event: eventName,
        customerLoggedIn: 1,
        customerId: data.id,
        customerEmail: encrypt(data.email).content,
        customerMobile: telephone,
        normalizedHashedEmail: data.extension_attributes?.normalized_hashed_email,
        normalizedHashedPhone: data.extension_attributes?.normalized_hashed_phone,
        normalizedEmail: data.email?.trim().toLowerCase(),
        unNormalizedEmail: data.email,
    };
}
export { CustomerLoginEvent };
