import React, { useEffect } from "react";
import { useGlobalOptions } from '@corratech/context-provider';

const OrderGrooveScriptLoader = () => {
    const { storeConfig: { ordergroove_subscription_general_public_id: ordergrooveSubPublicId } = {} } = useGlobalOptions();

    useEffect(() => {
        if (!ordergrooveSubPublicId) {
            return;
        }

        const OGScriptUrl = `https://static.ordergroove.com/${ordergrooveSubPublicId}/main.js`;
        let script;

        const addScriptWithDelay = () => {
            setTimeout(() => {
                if (!document.querySelector(`script[src="${OGScriptUrl}"]`)) {
                    script = document.createElement("script");
                    script.src = OGScriptUrl;
                    script.async = true;
                    document.head.appendChild(script);
                }
            }, 5000);
        };

        window.addEventListener("load", addScriptWithDelay);

        return () => {
            window.removeEventListener("load", addScriptWithDelay);
        };
    }, [ordergrooveSubPublicId]);

    return null;
};

export default OrderGrooveScriptLoader;