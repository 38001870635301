import React, {useCallback, useContext, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { string, object } from 'prop-types';
import { Form } from 'informed';
import {
    Field,
    Message,
    TextInput,
    combine,
    isRequired
} from '@corratech/form-components';
import { PasswordField } from '@corratech/password-field';
import {
    changePassword,
    LoaderStore,
    signIn
} from '@corratech/context-provider';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from 'react-bootstrap';
//import { FiCheck as CheckIcon, FiX as ErrorIcon } from 'react-icons/fi';
import { X as ErrorIcon, Check as CheckIcon } from 'react-feather';
require('./ResetPasswordForm.less');
import { handleEmailChange } from 'UtilPath/handleEmailChange';
import { useDebouncedCallback } from 'UtilPath/useDebouncedCallback';

export const ResetPasswordForm = props => {
    const [t] = useTranslation();
    const history = useHistory();
    const formApiRef = useRef(null);
    const setFormApi = useCallback(api => (formApiRef.current = api), []);

    const { className, css, enteredEmail, resetToken, updateEmail } = props;

    const LoadingIndicator = useContext(LoaderStore);

    const [password, setPassword] = useState('');

    const [confirmPassword, setConfirmPassword] = useState('');

    const [passwordIsValid, setPasswordIsValid] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);

    const [showError, setShowError] = useState('');

    const [loading, setLoading] = useState(false);

    const passwordsEntered = () => {
        return password !== '' && confirmPassword !== '';
    };

    const handleSubmit = async formState => {
        formState.password = password;

        try {
            setLoading(true);

            await changePassword(
                {
                    email: enteredEmail,
                    resetToken: resetToken,
                    newPassword: password
                },
                (state, errorMessage) => {
                    state ? setShowSuccess(true) : setShowError(errorMessage);
                    setLoading(false);

                    //Reset the url on sucessfully reset password
                    if (state) {
                        window.history.pushState(
                            {},
                            document.title,
                            window.location.pathname
                        );
                    }
                }
            );
        } catch (error) {
            setLoading(false);
        }
    };

    if (showSuccess) {
        return (
            <Alert variant={'success'}>
                <CheckIcon size={14} strokeWidth={'4'} color={'#000'} />
                {t(props.successText)}
            </Alert>
        );
    }

    if (showError) {
        return (
            <Alert variant={'danger'}>
                <ErrorIcon size={14} strokeWidth={'4'} color={'#B70020'} />
                <strong>{t(`Error.`)}</strong> {showError}
            </Alert>
        );
    }

    if (loading) {
        return <LoadingIndicator />;
    }

    const onEmailChange = useDebouncedCallback(
        (value) => handleEmailChange(value, formApiRef, updateEmail),
        300
    );

    return (
        <div
            aria-labelledby={'account-reset-password-heading'}
            className={
                'account-reset-form account-form-wrapper ' + (className || '')
            }
            css={css}
        >
            <div id="account-reset-password-heading" className={'title'}>
                {t(props.title)}
            </div>

            <p className={'sub-title'}>{t(props.subtitle)}</p>

            <Form onSubmit={handleSubmit} getApi={setFormApi}>
                <Field
                    label={t(`Email`)}
                    labelText={t(`email_address`)}
                    required={true}
                >
                    <TextInput
                        field="email"
                        type="email"
                        autoComplete="off"
                        placeholder={t(`Email`)}
                        id="email_address"
                        aria-required="true"
                        initialValue={enteredEmail}
                        disabled
                        onValueChange={onEmailChange}
                        data-cs-mask
                    />
                </Field>
                <Field labelText={t(`password`)}>
                    <PasswordField
                        className={'input-password-wrapper'}
                        {...props.passwordRequirements}
                        changeCallback={data => {
                            setPassword(data.password);
                            setPasswordIsValid(data.isValid);
                        }}
                        inputProps={{
                            name: 'password',
                            autoComplete: 'off',
                            placeholder: t(`Password`),
                            className: 'input-password'
                        }}
                        validatePasswordText={t(props.validatePasswordText)}
                    />
                </Field>
                <div
                    className={'confirm-reset-password'}
                    labelText={t(`confirm_password`)}
                >
                    <Field label={t(`Confirm Password`)} required={true}>
                        <TextInput
                            field="confirm"
                            type="password"
                            placeholder={t(`Confirm Password`)}
                            id="confirm_password"
                            aria-required="true"
                            validate={combine([
                                {
                                    fn: isRequired,
                                    text: t(props.requiredText)
                                }
                            ])}
                            validateOnChange
                            validateOnBlur
                            onChange={event =>
                                setConfirmPassword(event.target.value)
                            }
                        />

                        {passwordsEntered() &&
                            password !== confirmPassword &&
                            passwordIsValid && (
                                <Message
                                    fieldState={{
                                        error: t(`Passwords must match.`)
                                    }}
                                />
                            )}
                    </Field>
                </div>

                <Button
                    type="submit"
                    size="lg"
                    variant="primary"
                    block
                    disabled={
                        !(password === confirmPassword && passwordIsValid)
                    }
                >
                    {t(props.changePasswordButtonText)}
                </Button>
            </Form>
        </div>
    );
};

ResetPasswordForm.propTypes = {
    className: string,
    css: object,
    resetToken: string,
    enteredEmail: string,
    requiredText: string,
    successText: string,
    title: string,
    subtitle: string,
    validatePasswordText: string,
    changePasswordButtonText: string,
    passwordRequirements: object
};

ResetPasswordForm.defaultProps = {
    requiredText: 'This field is required',
    successText: 'Password Successfully Reset',
    title: 'Reset Password',
    subtitle: 'Reset your password below.',
    validatePasswordText:
        'A password must contain at least 3 of the following: lowercase, uppercase, digits, special characters.',
    changePasswordButtonText: 'Change Password',
    passwordRequirements: {
        minLength: 8,
        minScore: 2,
        validate: {
            isRequired: true,
            validatePassword: true
        }
    }
};
