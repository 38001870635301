import React from 'react';
import Select from 'react-select';
import { getIn } from 'formik';
import { ChevronDown as ChevronDownIcon } from 'react-feather';
import Icon from '../Icon';
import classNames from 'classnames/bind';
import { shape, string } from 'prop-types';
import style from '../Message/Message.less';

let examineClasses = classNames.bind(style);

const arrow = <Icon src={ChevronDownIcon} size={25} />;

const SelectInputFormik = ({
   field,
   items,
   form: { errors, touched, setFieldValue },
   after,
   before,
   enableError,
   setErrorClass,
   handleOnChange,
   ...rest
}) => {
    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);

    const className = errorMessage
        ? examineClasses('field-required-error')
        : examineClasses('');

    if (enableError) {
        errorMessage ? setErrorClass(true) : setErrorClass(false);
    }

    const options = items.map(({ value, label }) => ({
        value,
        label,
    }));

    return (
        <div className="select-box-wrapper">
            <Select
                options={options}
                value={options.find(option => option.value === field.value)}
                onChange={(option) => {
                    if (handleOnChange) {
                        handleOnChange(option.value);
                    }
                    setFieldValue(field.name, option.value);
                }}
                isSearchable={true} // Enable search functionality
                classNamePrefix="react-select"
                {...rest}
            />
            {touch && errorMessage && (
                <span className="root_error">
                    <p className={className}>{errorMessage}</p>
                </span>
            )}
        </div>
    );
};

SelectInputFormik.propTypes = {
    field: shape({
        name: string.isRequired,
        value: string,
    }).isRequired,
    items: shape([]).isRequired,
};

export default SelectInputFormik;
