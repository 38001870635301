import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Promo.less';
import { Button } from "react-bootstrap";

export const PromoLabel = ({labelObj}) => {
    const { label, code, text_hex, background_hex, icon_code } = labelObj;

    const [t] = useTranslation();
    const [copied, setCopied] = useState(false);

    const handleClick = () => {
        if (code && code.trim() !== '') {
            const textArea = document.createElement('textarea');
            textArea.value = code;
            document.body.appendChild(textArea);

            textArea.select();
            document.execCommand('copy');
            textArea.focus({ preventScroll: true });

            try {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            } catch (err) {
                console.error('Unable to copy to clipboard', err);
            }
            document.body.removeChild(textArea);
        }
    };
    const boxStyle = background_hex ? { backgroundColor: background_hex } : {};
    const textStyle = text_hex ? { color: text_hex } : {};

    return (
        <Button className="promo-label" onClick={handleClick} style={boxStyle} variant={"link"} aria-label={t('Click to copy promo code')}>
            {icon_code ? (
                <div
                    className="promo-label-svg-wrapper"
                    dangerouslySetInnerHTML={{__html: icon_code}}
                />
            ) : (
                <div className="promo-label-svg-wrapper">
                    <svg
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12.0333 5.60693L6.78334 0.356927C6.57918 0.123594 6.28751 0.00692749 5.96668 0.00692749H1.88334C1.24167 0.00692749 0.716675 0.531928 0.716675 1.17359V5.25693C0.716675 5.57776 0.833341 5.86943 1.06667 6.0736L6.31668 11.3236C6.52084 11.5278 6.81251 11.6736 7.13334 11.6736C7.45418 11.6736 7.74584 11.5278 7.95001 11.3236L12.0333 7.24026C12.2375 7.0361 12.3833 6.74443 12.3833 6.4236C12.3833 6.10276 12.2375 5.8111 12.0333 5.60693ZM2.75834 2.92359C2.26251 2.92359 1.88334 2.54443 1.88334 2.04859C1.88334 1.55276 2.26251 1.17359 2.75834 1.17359C3.25418 1.17359 3.63334 1.55276 3.63334 2.04859C3.63334 2.54443 3.25418 2.92359 2.75834 2.92359Z"
                            fill="#fff"
                        />
                    </svg>
                </div>
            )}
            <span className="promo-label-text" style={textStyle}>
                {copied ? t('Promo code copied!') : label}
            </span>
        </Button>
    );
};
