import { debounce } from './debounce';
import { useRef } from "react";

export const useDebouncedCallback = (callback, delay) => {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;

    return useRef(
        debounce((...args) => {
            callbackRef.current(...args);
        }, delay)
    ).current;
};
