import jsSHA from 'jssha/dist/sha256';

function CustomerEvents(tagData, addStoredData) {
    const {
        id,
        isLoggedIn,
        email,
        normalizedHashedEmail,
        normalizedHashedPhone,
        normalizedEmail,
        unNormalizedEmail
    } = tagData;
    const userLogState = {
        customerLoggedIn: !!isLoggedIn ? 'Yes' : 'No',
        customerEmail: email
    };
    if (!!id && !!isLoggedIn) {
        userLogState['customerId'] = id;
        let telephone = null;
        //get tel number from default billing address
        if (tagData.userData.addresses && tagData.userData.addresses.length > 0) {
            tagData.userData.addresses.forEach(address => {
                if (address.default_billing) {
                    telephone = address.telephone;
                }
            });
        }
        //if no - get from customer custom attributes
        if (!telephone) {
            tagData.userData.custom_attributes.forEach(attribute => {
                if (attribute.attribute_code === "telephone") {
                    telephone = attribute.value;
                }
            });
        }

        userLogState['customerMobile'] = telephone;
        userLogState['normalizedHashedEmail'] = normalizedHashedEmail;
        userLogState['normalizedHashedPhone'] = normalizedHashedPhone;
        userLogState['normalizedEmail'] = normalizedEmail;
        userLogState['unNormalizedEmail'] = unNormalizedEmail;
        let shaObj = new jsSHA('SHA-256', 'TEXT');
        shaObj.update(id + 'ELEMIS');
        window.cnstrcUserId = shaObj.getHash('HEX');
    } else {
        window.cnstrcUserId = '';
    }

    addStoredData({ userLogState: userLogState });
}
export { CustomerEvents };
