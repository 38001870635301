import { formatPrice } from '@corratech/tag-manager';

function PDPEvents(tagData) {
    const {
        category,
        name,
        sku,
        price,
        currencyCode,
        categoryPath,
        brand,
        thumbnail,
        bloomreach_attributes,
        variants,
        bottle_size,
        __typename
    } = tagData;

    // Extract the URL query parameter
    const urlParams = new URLSearchParams(window.location.search);
    const selectedBottleSize = parseInt(urlParams.get('bottle_size'), 10) || bottle_size || null;

    // Determine the variant and sale price
    let variantId = __typename === 'SimpleProduct' ? sku : '';
    let salePrice = formatPrice(price);
    let variantPrice;

    if (variants && selectedBottleSize) {
        const matchingVariant = variants.find(
            (variant) =>
                variant.attributes.some(
                    (attribute) =>
                        attribute.code === 'bottle_size' && attribute.value_index === selectedBottleSize
                )
        );

        if (matchingVariant) {
            variantId = matchingVariant.product.sku;
            variantPrice = matchingVariant.product?.price_range?.minimum_price?.final_price?.value;
            if (variantPrice) {
                salePrice = formatPrice(variantPrice);
            }
        }
    }

    return {
        event: 'product',
        ecommerce: {
            detail: {
                actionField: {
                    list: !!category ? `Category - ${category}` : ''
                },
                products: [
                    {
                        name,
                        id: sku,
                        price: formatPrice(price),
                        currency_code: currencyCode,
                        category: categoryPath,
                        brand,
                        thumbnailUrl: thumbnail.url ?? '',
                        category_level_1: bloomreach_attributes?.category_level_1 ?? '',
                        category_level_2: bloomreach_attributes?.category_level_2 ?? '',
                        category_level_3: bloomreach_attributes?.category_level_3 ?? '',
                        categories_path: bloomreach_attributes?.categories_path ?? '',
                        categories_ids: bloomreach_attributes?.categories_ids ?? '',
                        product_id: sku,
                        variant_id: variantId,
                        sale_price: salePrice
                    }
                ]
            }
        },
        _clear: true
    };
}

export { PDPEvents };
