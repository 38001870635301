import React, { useEffect, useState, Suspense, useContext } from 'react';
import { useMutation } from 'react-apollo';
import { Header } from './Header/Header';
import { SkipToContent } from '@corratech/skip-to-content';
import { Wifi as FiWifi } from 'react-feather';
import { Footer } from './Footer/Footer';
import { useUserVisitCookie } from './Footer/useUserVisitCookie';
import './Shell.less';
import { useLoadingIndicator, OptionsStore } from '@corratech/context-provider';
import { useLocation } from 'react-router-dom';
import { useSignifyd } from '@corratech/bll/Signifyd';
import { useListrak, useCartActivities } from '@corratech/listrak';
import { BackToTop } from './BackToTop';
import { useListrakCart } from '../AbandonedCart';
import { useCartOOS } from '../CartOOS';
import { isSignedIn } from '@corratech/context-provider';
import { useCookies } from 'react-cookie';
import { setOgAuthCookie } from 'ModulesPath/App/Queries/setOgAuthCookie';
import { deleteOgAuthCookie } from 'ModulesPath/App/Queries/deleteOgAuthCookie';
import { useDataLayerAction } from '@corratech/tag-manager';
import { ZenDeskLifeChat } from '../ZenDesk/zenDeskLifeChat';
import OrderGrooveScriptLoader from "../OrderGrooveScriptLoader/OrderGrooveScriptLoader";
import KlarnaScriptLoader from "../KlarnaScriptLoader/KlarnaScriptLoader";

const OnlineIndicator = () => <FiWifi attrs={{ width: 18 }} />;

export const Shell = props => {
    const { children, hasBeenOffline, isOnline } = props;
    const [cookies, removeCookie] = useCookies([]);
    const projectConfig = useContext(OptionsStore) || {};
    const [homeClass, setHomeClass] = useState(false);
    const LoadingIndicator = useLoadingIndicator();
    const storeConfig = projectConfig.storeConfig || {};
    let location = useLocation();
    const dataLayerAction = useDataLayerAction();
    /**
     * Initiate signifyd finger print
     * @params url: String
     * @params query [object || grpahql]
     */
    useSignifyd();

    /**
     * listrak abandoned cart implementation
     */
    useListrakCart();

    useCartOOS();

    useUserVisitCookie();

    const [setOgAuthCookieEvent] = useMutation(setOgAuthCookie);

    const [deleteOgAuthCookieEvent] = useMutation(deleteOgAuthCookie);

    const getCookieValue = name =>
        document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() ||
        '';
    const setOGCookie = () => {
        if (storeConfig.ordergroove_subscription_general_public_id) {
            const OG_AUTH_COOKIE_NAME = 'og_auth';
            let ogAuthCookie = getCookieValue(OG_AUTH_COOKIE_NAME);
            if (ogAuthCookie == 'undefined') {
                removeCookie(OG_AUTH_COOKIE_NAME);
            }
            if (isSignedIn()) {
                if (ogAuthCookie == null || ogAuthCookie == '') {
                    setOgAuthCookieEvent();
                }
            } else {
                if (ogAuthCookie != null) {
                    deleteOgAuthCookieEvent();
                }
            }
        }
    };

    useEffect(() => {
        // OG auth cookie reset functionality
        setOGCookie();
    }, [location]);

    useEffect(() => {
        dataLayerAction({
            type: 'BT_PAGE_VIEW_START',
            data: {
                pageDetails: {
                    pageUrl: location.pathname
                },
                timestamp: Date.now()
            }
        });
        setHomeClass(isHomePage());
    }, [location]);

    const isCO = () => {
        return location.pathname.indexOf('/checkout') == 0;
    };

    const isOrderConfirmation = () => {
        return location.pathname.indexOf('/express-checkout/success') == 0;
    };

    const isHomePage = () => !!(window.location.pathname === '/');

    return (
        <div className={`Main ${homeClass ? 'home-cms-page' : ''}`}>
            <SkipToContent />
            {!isCO() && !isOrderConfirmation() && (
                <Header onlineIndicator={OnlineIndicator} />
            )}
            <main className={'page'} id={'maincontent'}>
                <Suspense fallback={<LoadingIndicator />}>
                    <>
                        <a id="contentarea" tabIndex="-1"></a>
                        {children}
                        <BackToTop />
                    </>
                </Suspense>
            </main>
            <ZenDeskLifeChat />
            <OrderGrooveScriptLoader />
            <KlarnaScriptLoader />
            {!isCO() && !isOrderConfirmation() && <Footer />}
        </div>
    );
};
