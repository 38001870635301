import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { string } from 'prop-types';
import { Formik, Form, Field } from 'formik';
import {
    Checkbox,
    TextInputFormik,
    validateEmail
} from '@corratech/form-components';
import { Field as CorraField } from '../Field';
import { CartStore } from '@corratech/context-provider';
import { AutoSubmit } from '../AutoSubmit';
import { Gift } from 'react-feather';
import setGiftReceiptOnCart from './Queries/setGiftReceiptOnCart.graphql';
import './IsThisGiftForm.less';

export const IsThisGiftForm = props => {
    const [t] = useTranslation();

    const { cartState, dispatch } = useContext(CartStore);

    const [isThisGift, setIsThisGift] = useState({
        isThisGiftChecked: 0,
        isThisGiftEmail: ''
    });

    useEffect(() => {
        if (cartState.cart.gift_receipt) {
            setIsThisGift({
                isThisGiftChecked: cartState.cart.gift_receipt.status,
                isThisGiftEmail: cartState.cart.gift_receipt.email
            });
        }
    }, [cartState.cartId]);

    const [setGiftReceipt] = useMutation(setGiftReceiptOnCart, {
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: res => {
            const updatedCart = cartState.cart;
            updatedCart.gift_receipt = {
                status: res.setGiftReceiptOnCart.status,
                email: isThisGift.isThisGiftEmail
            };

            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });
        }
    });

    const handleChangeIsChecked = event => {
        const isChecked = +event.target.checked;

        setIsThisGift({
            isThisGiftChecked: isChecked,
            isThisGiftEmail: ''
        });

        const updatedCart = cartState.cart;
        updatedCart.gift_receipt = {
            status: isChecked,
            email: ''
        };

        dispatch({
            type: 'SET_CART',
            cart: updatedCart
        });
    };

    const updateState = email => {
        if ('' !== isThisGift.isThisGiftEmail && '' === email) {
            setIsThisGift({
                ...isThisGift,
                isThisGiftEmail: ''
            });

            const updatedCart = cartState.cart;
            updatedCart.gift_receipt = {
                status: isThisGift.isThisGiftChecked,
                email: ''
            };

            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });
        }
    };

    return (
        <div className={'is-this-gift-container'}>
            <Formik
                enableReinitialize={true}
                initialValues={isThisGift}
                validate={({ isThisGiftEmail }) => {
                    const errors = {};

                    updateState(isThisGiftEmail);

                    if (
                        isThisGift.isThisGiftChecked &&
                        isThisGiftEmail !== '' &&
                        validateEmail(
                            isThisGiftEmail,
                            null,
                            props.requiredText
                        ) !== undefined
                    ) {
                        errors.isThisGiftEmail = t(props.requiredText);
                    }

                    return errors;
                }}
                onSubmit={values => {
                    const { isThisGiftEmail } = values;

                    setGiftReceipt({
                        variables: {
                            isGift: isThisGift.isThisGiftChecked,
                            email: isThisGiftEmail
                        }
                    });

                    setIsThisGift({
                        ...isThisGift,
                        isThisGiftEmail: isThisGiftEmail
                    });
                }}
            >
                {({ values, submitForm, isValid, dirty , setFieldValue}) => (
                    <>
                        <Form>
                            <h2 className={'step-title is-this-gift-heading'}>
                                <span className={'is-this-gift-icon'}>
                                    <Gift size={22} color={'#000'} />
                                </span>
                                {t('IS THIS A GIFT ?')}
                            </h2>

                            <Checkbox
                                field="isThisGiftChecked"
                                id="isThisGiftChecked"
                                onChange={handleChangeIsChecked}
                                fieldState={{
                                    value: Boolean(isThisGift.isThisGiftChecked)
                                }}
                                label={t(
                                    "Yes, this is a gift (if so, we'll include a gift receipt)"
                                )}
                            />

                            <div
                                className={`${
                                    isThisGift.isThisGiftChecked ? 'show' : ''
                                } is-this-gift-email`}
                            >
                                <CorraField
                                    label={t('Recipient Email (optional)')}
                                    required={false}
                                >
                                    <Field
                                        name="isThisGiftEmail"
                                        component={TextInputFormik}
                                        type="email"
                                        inputMode="email"
                                        id="isThisGiftEmail"
                                        data-cs-mask
                                        onChange={(e) => {
                                            const value = e.target.value.trim() === '' ? '' : e.target.value.replace(/\s+/g, '');
                                            setFieldValue('email', value);
                                        }}
                                    />
                                </CorraField>
                            </div>
                        </Form>
                        <AutoSubmit
                            submitForm={submitForm}
                            isValid={isValid}
                            timeToDebounce={500}
                            dirty={dirty}
                            values={values}
                        />
                    </>
                )}
            </Formik>
        </div>
    );
};

IsThisGiftForm.propTypes = {
    requiredText: string
};

IsThisGiftForm.defaultProps = {
    requiredText: 'Please enter a valid gift recipient email.'
};
