import React, { useState, useEffect, useRef } from 'react';

const KlarnaComponent = ({ delay = 5000 }) => {
    const [KlarnaHookComponent, setKlarnaHookComponent] = useState(null);
    const isMounted = useRef(true);

    useEffect(() => {
        isMounted.current = true;

        const handleLoad = () => {
            const timer = setTimeout(() => {
                import('@corratech/klarna-promotions')
                    .then(({ useKlarna }) => {
                        if (isMounted.current) {
                            const KlarnaHookComponent = () => {
                                useKlarna();
                                return null;
                            };
                            setKlarnaHookComponent(() => KlarnaHookComponent);
                        }
                    })
                    .catch((error) => console.error('Error loading Klarna:', error));
            }, delay);

            return () => clearTimeout(timer);
        };

        if (document.readyState === 'complete') {
            handleLoad();
        } else {
            window.addEventListener('load', handleLoad);
            return () => {
                window.removeEventListener('load', handleLoad);
                isMounted.current = false;
            };
        }

        return () => {
            isMounted.current = false;
        };
    }, []);

    return (
        <>
            {KlarnaHookComponent && <KlarnaHookComponent />}
        </>
    );
};

export default KlarnaComponent;