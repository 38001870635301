import React, {
    useContext,
    useEffect,
    useState,
    Fragment,
    useCallback,
    useRef
} from 'react';
import { useHistory } from 'react-router-dom';
import { object, string, node, bool, func, number } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '@magento/peregrine';
import {
    CartStore,
    OverlayStore,
    LoaderStore
} from '@corratech/context-provider';
import { CartItemList } from '../CartItem/CartItemList';
import { MiniCartHeader } from '../MiniCartHeader/MiniCartHeader';
import { CostBlock } from '../CostBlock/CostBlock';
import { EmptyCartBlock } from '../EmptyCartBlock/EmptyCartBlock';
import { CartEditItem } from '../CartEditItem/CartEditItem';
import { Alert } from 'react-bootstrap';
import { Check as CheckIcon, X as DefaultErrorIcon } from 'react-feather';
import { CouponForm } from '@corratech/coupon-form';
//import { FreeGifts } from '@corratech/free-gifts';
import { FreeSamples } from '@corratech/free-samples';
require('./MiniCart.less');
import './ReplaceMiniCartWithShoppingCart.less';
import { useLazyQuery } from 'react-apollo';
import cartQ from 'ModulesPath/App/Queries/cartQuery.graphql';
import { Util } from '@magento/peregrine';
import { orderGrooveOptins } from 'UtilPath/orderGroove';
const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();
import { getCurrencySymbol, setPriceZeroes } from '../util';
import { PaymentIcons } from '../PaymentIcons/PaymentIcons';
import { useGlobalOptions } from '@corratech/context-provider';
import KlarnaScriptLoader from "ModulesPath/KlarnaScriptLoader/KlarnaScriptLoader";

export const MiniCart = props => {
    const {
        emptyCartBlock,
        children,
        className,
        css,
        showCount,
        canEdit,
        shouldRedirect,
        showTotalCount,
        mobileWidth,
        tabletWidth,
        colorAttrCode,
        qtyLoaderProps,
        costBlockVariant,
        proceedToCheckout,
        showPromoBlock,
        SuccessIcon,
        ErrorIcon,
        noDefaultVal,
        CartItem,
        CartEditItem,
        displayAsShoppingCart,
        showFreeGifts,
        addConfigurableProductToCartGraphql,
        applyCouponToCartGraphql,
        removeCouponFromCartGraphql,
        removeItemFromCartGraphql,
        updateCartItemsGraphql,
        getCartQuery,
        promoTitle,
        successMsg1,
        showFreeSamples,
        cartTitle
    } = props;

    const [t] = useTranslation();

    const history = useHistory();

    const [open, setOpen] = useState(false);

    const [isEditingItem, setIsEditingItem] = useState(false);

    const [editItem, setEditItem] = useState(null);

    const [showMessage, setShowMessage] = useState(false);

    const { cartState, dispatch } = useContext(CartStore);

    const { overlayState, callbackDispatch, overlayDispatch } = useContext(
        OverlayStore
    );

    const EmptyCart = emptyCartBlock;

    const windowSize = useWindowSize();

    const isMobile = windowSize.innerWidth <= mobileWidth;

    const LoadingIndicator = useContext(LoaderStore);

    const minicartWrapRef = useRef();
    const middleContainerRef = useRef();
    const [cartItemsId, setCartItemsId] = useState([]);

    const totalQty = cartState.cart.items
        ? cartState.cart.items.reduce((qty, item) => qty + item.quantity, 0)
        : 0;

    const redirectToPage = path => {
        if (path) {
            history.push(path);
        }
    };

    const [isOGPresent, setIsOGPresent] = useState(false);
    const { storeConfig: { ordergroove_subscription_general_public_id: ordergrooveSubPublicId } = {} } = useGlobalOptions();
    const OGScriptUrl = ordergrooveSubPublicId ? 'https://static.ordergroove.com/' + ordergrooveSubPublicId + '/main.js' : null;

    useEffect(() => {
        const ids =
            cartState.cart.items &&
            cartState.cart.items.map(item => {
                if (item.product.__typename === 'ConfigurableProduct')
                    return `${item.product.variants &&
                        item.product.variants.find(
                            variant => variant.product.sku === item.itemsku
                        ).product.id}`;

                return `${item.product.id}`;
            });
        setCartItemsId(ids);
    }, [cartState.cart]);

    useEffect(() => {
        setIsOGPresent(orderGrooveOptins(cartItemsId).length > 0);
    }, [cartItemsId]);

    useEffect(() => {
        closeCart();
    }, [history.location]);

    useEffect(() => {
        callbackDispatch({ type: 'PUSH', data: () => setOpen(false) });
    }, []);

    const [qtyUpdating, setQtyUpdating] = useState(false);

    /**
     * Implemented as a fix for disappearing overlay
     * while resize the window and keep overlay show
     * Cause: Conflict with account drawer overlay
     */
    useEffect(() => {
        if (open) {
            overlayDispatch({ type: 'SHOW' });
            setShowMessage(false);
            if (minicartWrapRef.current) {
                minicartWrapRef.current.scrollTop = 0;
            }
            if (middleContainerRef.current) {
                middleContainerRef.current.scrollTop = 0;
            }
        }
    }, [overlayState.class]);

    useEffect(() => {
        if (isMobile) {
            closeCart();
        }
        return () => {
            overlayDispatch({ type: 'HIDE' });
        };
    }, [isMobile]);

    useEffect(() => {
        if (!isEditingItem) {
            setEditItem(null);
        }
    }, [isEditingItem]);

    useEffect(() => {
        autoOpen(cartState.shouldOpenDrawer);
    }, [cartState.shouldOpenDrawer]);

    useEffect(() => {
        const minicartPaymentMethod = storage.getItem(
            'minicart-payment-method'
        );
        if (cartState.placedOrder) {
            if (minicartPaymentMethod) {
                redirectToPage('/express-checkout/success');
                storage.removeItem('minicart-payment-method');
            } else {
                redirectToPage('/');
            }
        }
    }, [cartState.placedOrder]);

    const [cartQuery, { loading }] = useLazyQuery(cartQ, {
        fetchPolicy: 'no-cache',
        variables: {
            cartId: cartState.cartId,
            isSignedIn: !!cartState.cart.authenticated
        },
        onCompleted: res => {
            setQtyUpdating(false);
            dispatch({
                type: 'SET_CART',
                cart: res.cart
            });
        }
    });

    useEffect(() => {
        if (cartState.cartId) {
            cartQuery();
        }
    }, [cartState.cartId]);

    const autoOpen = shouldOpen => {
        if (shouldOpen) {
            dispatch({
                type: 'SHOULD_OPEN_DRAWER',
                shouldOpenDrawer: false
            });
            setOpen(true);
            overlayDispatch({ type: open ? 'HIDE' : 'SHOW' });
        }
    };

    const closeCart = () => {
        overlayDispatch({ type: 'HIDE' });
        setOpen(false);
    };

    const [isKlarnaLoaded, setIsKlarnaLoaded] = useState(null);

    const handleClick = () => {
        setIsKlarnaLoaded(true);
        openCart();
    };

    const openCart = () => {
        if (shouldRedirect) {
            history.push(redirectLink);
        } else {
            if (ordergrooveSubPublicId && !document.querySelector(`script[src="${OGScriptUrl}"]`)) {
                const script = document.createElement("script");
                script.src = OGScriptUrl;
                script.async = true;
                document.head.appendChild(script);
            }

            overlayDispatch({ type: 'SHOW' });
            setOpen(!open);
            setShowMessage(false);
        }
    };

    const isPaymentMethodAvailable = methodCode => {
        const isPaymentAvailable =
            cartState.cart &&
            cartState.cart.available_payment_methods &&
            cartState.cart.available_payment_methods.findIndex(
                x => x.code === methodCode
            ) > -1;
        const isAppleDevice =
            window.ApplePaySession &&
            ApplePaySession.canMakePayments() === true;

        if (isPaymentAvailable) {
            if (isAppleDevice) {
                return true;
            }
        } else {
            return false;
        }
    };

    const isCartEmpty = () => {
        const { cart } = cartState;

        return !cart || !cart.items || cart.items.length === 0;
    };

    const handleIsEditingItem = state => {
        setIsEditingItem(state);
    };

    const handleQtyEditing = state => {
        setQtyUpdating(state);
    };

    return (
        <div
            className={
                'minicart-wrapper ' +
                (className || '') +
                (displayAsShoppingCart ? 'no-minicart' : '')
            }
            css={css}
        >
            <button
                className={`cart-toggle ${
                    cartState.cart.items && cartState.cart.items.length !== 0
                        ? ''
                        : 'no-item'
                }`}
                onClick={handleClick}
                aria-label={'Minicart'}
            >
                {children}
                {showCount && !showTotalCount && (
                    <div className="cart-items-wrapper">
                        <span className="cart-items">
                            {cartState.cart.items &&
                            cartState.cart.items.length !== 0
                                ? cartState.cart.total_quantity &&
                                  cartState.cart.total_quantity > 0
                                    ? cartState.cart.total_quantity
                                    : cartState.cart.items.length
                                : noDefaultVal
                                ? ''
                                : 0}
                        </span>
                    </div>
                )}
                {showCount && showTotalCount && (
                    <div className="cart-items-wrapper">
                        <span className="cart-items">{totalQty}</span>
                    </div>
                )}
            </button>
            <div
                className={
                    (open ? 'minicart-open minicart' : 'minicart') +
                    (isCartEmpty() ? ' empty-cart-container ' : '') +
                    (qtyUpdating ? ' loading' : '')
                }
                ref={minicartWrapRef}
            >
                {qtyUpdating && <LoadingIndicator />}
                <Fragment>
                    {(!isCartEmpty() || isEditingItem) && (
                        <MiniCartHeader
                            closeCart={closeCart}
                            title={
                                isEditingItem
                                    ? t('Edit Cart Item')
                                    : t(cartTitle)
                            }
                        />
                    )}

                    {isKlarnaLoaded && <KlarnaScriptLoader delay={0} />}

                    {isEditingItem && (
                        <CartEditItem
                            setIsEditingItem={handleIsEditingItem}
                            setShowMessage={setShowMessage}
                            editItem={editItem}
                            ErrorIcon={ErrorIcon}
                            currencyCode={
                                isCartEmpty()
                                    ? 'USD'
                                    : cartState.cart.prices.grand_total.currency
                            }
                            colorAttrCode={colorAttrCode}
                            isMinicart={true}
                            addConfigurableProductToCartGraphql={
                                addConfigurableProductToCartGraphql
                            }
                            removeItemFromCartGraphql={
                                removeItemFromCartGraphql
                            }
                            updateCartItemsGraphql={updateCartItemsGraphql}
                        />
                    )}

                    {!isCartEmpty() && !isEditingItem && (
                        <>
                            <div
                                className={'middle-container'}
                                ref={middleContainerRef}
                            >
                                {showMessage && (
                                    <Alert variant={'success'}>
                                        {SuccessIcon}
                                        {t(props.successfullyUpdatedText)}
                                    </Alert>
                                )}
                                <div className={'minicart-info'}>
                                    <span className={'minicart-info-count'}>{cartState.cart.total_quantity} {cartState.cart.total_quantity > 1 ? t('items in bag') : t('item in bag')}</span>
                                    <span  className={'minicart-info-subtotal'}>{getCurrencySymbol(
                                        cartState.cart.prices
                                            .grand_total.currency
                                    ) +
                                    setPriceZeroes(
                                        cartState.cart.prices
                                            .grand_total
                                            .value
                                    )}</span>
                                </div>
                                <CartItemList
                                    canEdit={canEdit}
                                    hasConfirmation={true}
                                    imageWidth={108}
                                    setIsEditingItem={handleIsEditingItem}
                                    setEditItem={setEditItem}
                                    qtyLoaderProps={qtyLoaderProps}
                                    CartItem={CartItem}
                                    removeItemFromCartGraphql={
                                        removeItemFromCartGraphql
                                    }
                                    updateCartItemsGraphql={
                                        updateCartItemsGraphql
                                    }
                                    setQtyUpdating={handleQtyEditing}
                                    setIsOGPresent={setIsOGPresent}
                                    cartItemsId={cartItemsId}
                                    cartQuery={cartQuery}
                                />
                                {showFreeSamples && (
                                    <FreeSamples
                                        ErrorIcon={ErrorIcon}
                                        removeItemFromCartGraphql={
                                            removeItemFromCartGraphql
                                        }
                                    />
                                )}
                            </div>
                            <div
                                className={`minicart-bottom ${
                                    isPaymentMethodAvailable(
                                        'braintree_applepay'
                                    )
                                        ? 'has-apple-pay'
                                        : ''
                                }`}
                            >
                                {showPromoBlock && (
                                    <Fragment>
                                        <CouponForm
                                            applyCouponToCartGraphql={
                                                applyCouponToCartGraphql
                                            }
                                            removeCouponFromCartGraphql={
                                                removeCouponFromCartGraphql
                                            }
                                            successMsg1={successMsg1}
                                            ErrorIcon={ErrorIcon}
                                            promoTitle={promoTitle}
                                            showFreeGifts={showFreeGifts}
                                            removeItemFromCartGraphql={
                                                removeItemFromCartGraphql
                                            }
                                        />
                                    </Fragment>
                                )}
                                {loading ? (
                                    <LoadingIndicator />
                                ) : (
                                    <CostBlock
                                        isKlarnaLoaded={isKlarnaLoaded}
                                        cart={cartState.cart}
                                        closeCart={closeCart}
                                        proceedToCheckout={proceedToCheckout}
                                        costBlockVariant={costBlockVariant}
                                        showTotalCount={showTotalCount}
                                        isOGPresent={isOGPresent}
                                    />
                                )}
                                <PaymentIcons/>
                            </div>
                        </>
                    )}
                    {isCartEmpty() && !isEditingItem && (
                        <EmptyCart closeCart={closeCart} />
                    )}
                </Fragment>
            </div>
        </div>
    );
};

export default MiniCart;

MiniCart.propTypes = {
    children: node,
    className: string,
    css: object,
    showCount: bool,
    emptyCartBlock: func,
    redirectLink: string,
    shouldRedirect: bool,
    showTotalCount: bool,
    successfullyUpdatedText: string,
    mobileWidth: number,
    tabletWidth: number,
    canEdit: bool,
    colorAttrCode: string,
    qtyLoaderProps: object,
    costBlockVariant: string,
    proceedToCheckout: bool,
    showPromoBlock: bool,
    SuccessIcon: object,
    noDefaultVal: bool,
    CartItem: func,
    CartEditItem: func,
    displayAsShoppingCart: bool,
    rearrangeElem: bool,
    showFreeGifts: bool,
    showFreeSamples: bool,
    cartTitle: string
};

MiniCart.defaultProps = {
    showCount: true,
    emptyCartBlock: EmptyCartBlock,
    redirectLink: '/my-cart',
    shouldRedirect: false,
    showTotalCount: false, // To display total item qty in mini cart icon set the flag to true
    successfullyUpdatedText: 'Successfully updated item.',
    mobileWidth: 767,
    tabletWidth: 1024,
    canEdit: true,
    colorAttrCode: 'fashion_color',
    qtyLoaderProps: { height: 20, width: 120 },
    costBlockVariant: 'detailed-collapsible',
    proceedToCheckout: true,
    showPromoBlock: false,
    SuccessIcon: <CheckIcon size={14} strokeWidth={'4'} color={'#000'} />,
    ErrorIcon: <DefaultErrorIcon size={14} strokeWidth={'4'} color={'#000'} />,
    noDefaultVal: false,
    CartEditItem: CartEditItem,
    displayAsShoppingCart: false,
    rearrangeElem: false,
    showFreeGifts: false,
    showFreeSamples: false,
    cartTitle: 'Shopping Cart'
};
