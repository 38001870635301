import React, { useEffect, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { Alert } from 'react-bootstrap';
import {
    combine,
    isRequired,
    Field,
    TextInput,
    Checkbox
} from '@corratech/form-components';
import { Form } from 'informed';
import { Error } from 'ModulesPath/Icons';
import { Check } from 'react-feather';
import { useLoadingIndicator, OptionsStore } from '@corratech/context-provider';
import sendAttentiveSms from 'ModulesPath/SMSMarketingCheckbox/Data/Queries/sendAttentiveSMSData.graphql';
import { useReCaptchaStoreConfig } from 'ModulesPath/Checkout/useReCaptchaStoreConfig';
import { ReCaptcha } from 'react-recaptcha-v3';
import loadReCaptcha from '@corratech/google-recaptcha-v3/src/loadReCaptcha';
import { Error as ErrorIcon } from 'ModulesPath/Icons';
import { useDataLayerAction } from '@corratech/tag-manager';

export const SMSSignUp = props => {
    const [t] = useTranslation();
    const [isSubscribed, setSubscribed] = useState(false);
    const [error, setError] = useState(false);
    const apiRef = useRef();
    const projectConfig = useContext(OptionsStore) || {};
    const storeConfig = projectConfig.storeConfig || {};
    const { code } = storeConfig;
    var personalizedText = code == 'us' ? 'personalized' : 'personalised';

    const LoadingIndicator = useLoadingIndicator();

    //ReCaptcha
    const { actionV3SMSSignup } = useReCaptchaStoreConfig();
    const [isReCaptchaLoaded, setIsReCaptchaLoaded] = useState(false);
    const [reCaptchaToken, setReCaptchaToken] = useState(null);
    const [captchaInvalidMsg, setCaptchaInvalidMsg] = useState(null);
    const dataLayerAction = useDataLayerAction();
    const isV3FooterSMSSignupEnable = !!(actionV3SMSSignup && actionV3SMSSignup === 'recaptcha_v3');

    let recaptchaV3Publickey = '';

    if (
        projectConfig.storeConfig &&
        projectConfig.storeConfig.recaptcha_v3_public_key
    ) {
        recaptchaV3Publickey =
            projectConfig.storeConfig.recaptcha_v3_public_key;
    }

    useEffect(() => {
        const scriptLoaded = loadReCaptcha(recaptchaV3Publickey, () => {
            setIsReCaptchaLoaded(true);
        });
        if (!scriptLoaded) {
            setIsReCaptchaLoaded(true);
        }
    }, [recaptchaV3Publickey]);

    const verifyReCaptchaTokenFooterSMSSignupCallback = token => {
        setReCaptchaToken(token);
        setInterval(function() {
            resetReCaptchaTokenFooterSMSSignup();
        }, 100 * 1000);
    };

    const resetReCaptchaTokenFooterSMSSignup = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, {
                    action: 'sms_signup'
                })
                .then(function(token) {
                    setReCaptchaToken(token);
                });
        }
    };

    const validateCheckbox = (value, values, text) => {
        return !value ? text : undefined;
    };

    const [
        setSendAttentiveSMSData,
        { loading: attentiveLoading }
    ] = useMutation(sendAttentiveSms, {
        onCompleted: res => {
            if (res.sendAttentiveSms && res.sendAttentiveSms.status) {
                setSubscribed(true);
                dataLayerAction({
                    type: 'SMS_SIGNUP',
                    data: {}
                });
            } else {
                setError(
                    'The phone number submission has failed. Please try again.'
                );
            }
            resetReCaptchaTokenFooterSMSSignup();
            apiRef.current.reset();
        },
        onError: error => {
            let reCaptchaFailure = false;
            let captchaInvalidMsg = null;

            if (error?.graphQLErrors) {
                for (var idx = 0; idx < error.graphQLErrors.length; idx++) {
                    if (
                        error.graphQLErrors[idx]?.extensions?.category ===
                        'graphql-recaptcha'
                    ) {
                        captchaInvalidMsg = error.graphQLErrors[idx]?.message;
                        reCaptchaFailure = true;
                    }
                }
            }

            setError(error?.message);

            if (!reCaptchaFailure) {
                resetReCaptchaTokenFooterSMSSignup();
            }

            if (captchaInvalidMsg) {
                setCaptchaInvalidMsg(captchaInvalidMsg);
            }
        }
    });

    const smsSubscribe = async formState => {
        setCaptchaInvalidMsg(null);
        setSendAttentiveSMSData({
            variables: {
                input: {
                    phone: formState.telephone,
                    area: 'footer'
                }
            },
            context: {
                headers: {
                    'X-ReCaptcha': reCaptchaToken
                }
            }
        });
    };

    const handleFormSubmit = formState => {
        setSubscribed(false);
        setError(false);
        smsSubscribe(formState);
    };

    useEffect(() => {
        if (isSubscribed || error) {
            const timer = setTimeout(() => {
                setSubscribed(false);
                setError(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [isSubscribed, error]);

    if (!isReCaptchaLoaded) {
        return <LoadingIndicator />;
    }

    const agreementContent = (
        <>
            <span>
                {t(
                    `By submitting this form, you agree to receive recurring automated promotional and ` +
                        personalizedText +
                        ` marketing text messages (e.g. cart reminders) from ELEMIS at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg & data rates may apply. View`
                )}
            </span>
            <a
                target="_blank"
                href={props.termsLink}
                className={'privacy-link'}
            >
                {t(`Terms`)}
            </a>
            {` & `}
            <a
                target="_blank"
                href={props.privacyLink}
                className={'privacy-link'}
            >
                {t(`Privacy`)}
            </a>
        </>
    );

    const onPhoneChange = (value) => {
        if (!value) return;

        if (typeof value === 'number') {
            value = value.toString();
        }

        const fieldName = 'telephone';
        const clearPhoneNumberValue = (value || '').trim() === '' ? '' : value.replace(/[\D\s]/g, '');
        const { current: formApi } = apiRef;

        if (formApi) {
            const currentEmailValue = formApi.getValue(fieldName);

            if (currentEmailValue && currentEmailValue !== clearPhoneNumberValue) {
                formApi.setValue(fieldName, clearPhoneNumberValue);
            }
        }
    };

    return (
        <Form
            className={'sms-subscription'}
            getApi={formApi => (apiRef.current = formApi)}
            onSubmit={handleFormSubmit}
        >
            {isV3FooterSMSSignupEnable && (
                <ReCaptcha
                    action="sms_signup"
                    sitekey={recaptchaV3Publickey}
                    verifyCallback={verifyReCaptchaTokenFooterSMSSignupCallback}
                />
            )}
            <div
                className={`footer-subscription-wrapper ${props.className ||
                    ''}`}
            >
                <strong
                    role="heading"
                    aria-level="2"
                    className={'footer-top-heading'}
                >
                    {props.title}
                </strong>
                <Field label={t(props.inputTelephoneLabel)}>
                    <TextInput
                        type={'text'}
                        inputMode="numeric"
                        title={t(props.inputTelephoneLabel)}
                        field="telephone"
                        pattern="\d*"
                        validate={combine([
                            {
                                fn: isRequired,
                                text: t(props.requiredText)
                            }
                        ])}
                        validateOnBlur
                        placeholder={t('Enter phone number')}
                        onChange={(e) => {
                            onPhoneChange( e.target.value)
                        }}
                    />
                </Field>
                <div className="agreement-checkbox">
                    <Checkbox
                        field="sms_subscription_agreement"
                        id="sms_subscription_agreement"
                        validate={combine([
                            {
                                fn: validateCheckbox,
                                text: t(props.requiredText)
                            }
                        ])}
                        label={<span>{agreementContent}</span>}
                    />
                </div>
                {isSubscribed && (
                    <Alert variant={'success'}>
                        <Check size="14" strokeWidth={'4'} color={'#000'} />
                        {t(`Cheers. You’re on the list.`)}
                    </Alert>
                )}
                {captchaInvalidMsg == null && error && (
                    <Alert variant={'danger'}>
                        <Error size={9} color={'#B30015'} />
                        {t(error)}
                    </Alert>
                )}
                {isV3FooterSMSSignupEnable && captchaInvalidMsg && (
                    <Alert variant="danger">
                        <ErrorIcon size={9} color={'#B70020'} />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: captchaInvalidMsg
                            }}
                        />
                    </Alert>
                )}
                <Button type={'submit'} size="lg" variant="primary">
                    {t('Submit')}
                </Button>
            </div>
        </Form>
    );
};

SMSSignUp.propTypes = {
    title: PropTypes.string,
    requiredText: PropTypes.string,
    inputTelephoneLabel: PropTypes.string
};

SMSSignUp.defaultProps = {
    requiredText: 'This is a required field.',
    inputTelephoneLabel: 'Phone Number',
    termsLink: 'http://attn.tv/elemis/terms.html',
    privacyLink: 'https://attnl.tv/legal/p/A2s'
};
