export const handleEmailChange = (value, formApiRef, updateEmail, hideErrorMessage, fieldName = 'email') => {
    const valueWithoutSpaces = (value || '').trim() === '' ? '' : value.replace(/\s+/g, '');
    const { current: formApi } = formApiRef;

    if (formApi) {
        const currentEmailValue = formApi.getValue(fieldName);

        if (currentEmailValue && currentEmailValue !== valueWithoutSpaces) {
            formApi.setValue(fieldName, valueWithoutSpaces);
        }
    }

    if (updateEmail) updateEmail(valueWithoutSpaces);
    if (hideErrorMessage) hideErrorMessage();
};
